import { Flex, Header, Toaster } from 'front-commons/ds';
import { useStorageWatcher } from 'front-commons/hooks';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from 'containers/Footer';
import { storageKeys } from 'shared/storage';
import useFidelity from 'stores/fidelity';
import useCatalog from 'stores/catalog';
import useBasket from 'stores/basket';
import useCustomer from 'stores/customer';
import useDrawer from 'stores/drawer';
import useFeatures from 'stores/features';
import usePos from 'stores/pos';
import { store } from 'stores/store';
import { findCategories } from 'services/category';
import { getSearchPreview } from 'services/products';
import { searchEventGTM, selectContentGTM } from 'shared/gtm';
import usePosSearch from 'shared/pos/usePosSearch';
import {
	canAccessThisPage,
	comparePaths,
	getStartedFromState,
	handleWatchCustomerPhone,
	needRedirectByContext,
	getUserMenuItems,
} from './helpers';
import { PageGlobalProps } from './interfaces';
import paths from './pages';
import { roleParser } from 'containers/ManagePersons/helpers';

function ProtectedRoute({
	protectType: pageType,
	redirectTo,
	headerType,
	needContexts,
	showFooter = true,
}: PageGlobalProps) {
	useStorageWatcher({ key: storageKeys.TOKEN, methods: ['removeItem', 'setItem'], storageType: 'localStorage' });

	const {
		basketStore: { basket, loading: loadingBasket },
		handleOpenBasket,
	} = useBasket();
	const {
		catalogStore: { catalogMenuItems },
	} = useCatalog();
	const { customerStore, handleResetUserIsLoggingOut, handleSetLogout, mainCustomerPage, simulatedViewType } =
		useCustomer();
	const { drawerStore, handleOpenDrawer } = useDrawer();
	const { featuresStore: features } = useFeatures();
	const {
		fidelityStore,
		handleClearRetryFetchData,
		handleRetryFetchData,
		handleSetFidelityData,
		isRetryFetchDataExpired,
		navigateToFidelityPage,
	} = useFidelity();
	const location = useLocation();
	const navigate = useNavigate();
	const { posStore, handleSelectPos, selectedPos } = usePos();

	const hasNotAnyOpenedDrawer = useMemo(() => {
		return !drawerStore.drawers.filter((drawer) => drawer.open).length;
	}, [drawerStore.drawers]);

	const pos = posStore.selectedPos;
	const { token, data: customer, provider: customerProvider } = customerStore;

	const initialToken = useMemo(() => store.getState().customerReducer.token, []);

	const volativeHeaderType = headerType === 'logged_and_not_logged' && customer ? 'logged' : 'not_logged';

	const redirect = {
		private: !token && '/login?logout=rdt',
		not_logged: !!token && customer && (redirectTo || mainCustomerPage()),
		public: false,
		by_context: needRedirectByContext(needContexts, { pharmacyContext: pos, customerContext: customer }),
		external_provider: canAccessThisPage(customerProvider, location, token),
	};

	const userMenuItems = getUserMenuItems(pos)

	useEffect(() => {
		handleWatchCustomerPhone(
			customer.phoneNumber,
			customer.phoneNumberConfirmed,
			customer.id,
			location.pathname,
			pageType,
			navigate,
			features,
		);

		const atualPage = paths.find((path) => comparePaths(path.pathname, location.pathname));

		if (atualPage) {
			const externalCustomer = customerProvider !== 'PARCEIRO_HYPERA' || customerProvider === null;

			if (atualPage.protectType !== 'external_provider' && externalCustomer) {
				handleSetLogout(true);
			}

			if (atualPage.protectType === 'private' && !token) {
				navigate('/login?logout=usf', { replace: true });
			}

			if (atualPage.pathname === '/minhas-farmacias' && !location.state) {
				handleResetUserIsLoggingOut();
			}
		}
	}, [location]);

	useEffect(() => {
		if (!initialToken) {
			handleResetUserIsLoggingOut();
		}
	}, []);

	if (pageType && redirect[pageType] && !(location.pathname === '/login')) {
		let redirectTo = String(redirect[pageType]);
		if (location.search.includes('utm') && redirectTo.includes('login')) redirectTo += location.search;

		return (
			<Navigate
				to={redirectTo}
				replace
				state={getStartedFromState({ pathnameRedirecting: redirectTo, location, hasToken: !!initialToken })}
			/>
		);
	}

	return (
		<>
			<Flex display="initial" position="sticky" top="0" zIndex={100}>
				{headerType && headerType !== 'hide' && (
					<Header
						basketData={basket}
						catalogMenuItems={catalogMenuItems}
						customerStore={customerStore}
						fidelityStore={fidelityStore}
						findCategories={findCategories}
						getSearchPreview={getSearchPreview}
						getUsePosSearch={usePosSearch}
						handleClearRetryFetchData={handleClearRetryFetchData}
						handleOpenBasket={handleOpenBasket}
						handleOpenDrawer={handleOpenDrawer}
						handleRetryFetchData={handleRetryFetchData}
						handleSelectPos={handleSelectPos}
						handleSetFidelityData={handleSetFidelityData}
						imageProvider={import.meta.env.VITE_STATIC_STORAGE_BASE}
						isRetryFetchDataExpired={isRetryFetchDataExpired}
						isSuggestionsProductNotifierExpired
						loadingBasket={loadingBasket}
						loadingPos={posStore.loading}
						mainCustomerPage={mainCustomerPage}
						myPos={posStore.myPos}
						navigateToFidelityPage={navigateToFidelityPage}
						posStore={posStore}
						searchEventGTM={searchEventGTM}
						selectContentGTM={selectContentGTM}
						selectedPos={selectedPos}
						simulatedViewType={simulatedViewType}
						type={headerType === 'logged_and_not_logged' ? volativeHeaderType : headerType}
						userMenu={{
							data:
								pos.role === 'SUPERVISOR'
									? userMenuItems.filter((item: any) => item.text !== 'Permissões de acesso')
									: userMenuItems,
							onLogout: () => handleSetLogout(true),
							userName: customer?.name,
							userRole: pos?.role && roleParser(pos?.role),
							linkToSimulated: !!simulatedViewType() ? '/simular-farmacia' : undefined,
						}}
					/>
				)}

				{hasNotAnyOpenedDrawer && <Toaster target="desktop" />}
			</Flex>

			{hasNotAnyOpenedDrawer && <Toaster target="mobile" />}
			<Outlet />
			{showFooter && <Footer />}
		</>
	);
}

export default ProtectedRoute;
