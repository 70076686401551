import { Alert, Button, Flex, Grid, Icon, Input, Typography, notify } from 'front-commons/ds';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DistributorsConflicts from 'containers/Dialogs/DistributorsConflicts';
import DistributorsDragAndDrop from 'containers/DragAndDrop/DistributorsDragAndDrop';
import { ColumnsProps, DistributorsCallToActionProps } from 'containers/DragAndDrop/DistributorsDragAndDrop/interfaces';
import {
	BusinessUnitResponse,
	BusinessUnitResponseEnum,
	DistributorsSourceParams,
} from 'pages/pos/Distributors/interfaces';
import { deleteDistributors, orderOrAddDistributors, posDistributorsCheck } from 'services/pos';
import { updateLoopingProviderGTM } from 'shared/gtm';
import useDialog from 'stores/dialog';
import usePos from 'stores/pos';
import {
	handleAddDistributor,
	handleRemoveDistributor,
	handleReorderDistributor,
	handleRePositionDistributor,
} from './helpers';
import { CardsRenderProps } from './interfaces';
import useCustomer from 'stores/customer';
import { useMediaQuery } from 'front-commons/hooks';
import { theme } from 'front-commons/ds/core/tokens';
import useBasket from 'stores/basket';

export default function DistributorsTabs({
	type = 'SIMPLE',
	posList,
	sourceId,
	selected,
	isDrawer,
	available,
	CustomCTA,
	conflicts,
	sourceType,
	hideSearch = false,
	pharmacyId,
	alertMessage,
	forceShowColumns,
	customDescription,
	lastDistributorConfigSaved,
	emptyAvailableDistributorsMessage,
	onAdd,
	onRemove,
	onReorder,
	setSelected,
	handleLoading,
	onAfterSimpleAdd,
	onAfterSimpleRemove,
	validateDistributors,
	setLastDistributorConfigSaved,
	handleRefetchSelectedDistributors,
}: CardsRenderProps) {
	const navigate = useNavigate();
	const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.medium})`);

	const { handleOpenDialog } = useDialog();
	const { handleHasPermission } = useCustomer();
	const { handleSelectPos, posStore, handleUpdateDistributor } = usePos();
	const {
		handleGetBasketData,
		basketStore: { basket: basketData },
	} = useBasket();

	const [filter, setFilter] = useState('');
	const [checkLoading, setCheckLoading] = useState<string | false>(false);

	const isPED = !!available[BusinessUnitResponseEnum.IsPED];
	const canBuy = !!selected?.distributors?.length && !!available?.distributors?.length;

	const hasPermission = handleHasPermission('DISTRIBUTOR_EDIT');

	const handleFilter = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(value);
	};

	const transactionIds = {
		[`${sourceType || 'businessUnit'}Id`]: sourceId,
	} as Record<`${DistributorsSourceParams['type']}Id`, DistributorsSourceParams['id']>;

	const handleAdd = useCallback(
		(distributorId: string, indexPosition?: number) => {
			const temp: BusinessUnitResponse['distributors'] = [];
			const currentDistributor = available.distributors.find((distributor) => distributor.id === distributorId);

			onAdd?.(distributorId, indexPosition);

			setSelected?.((prevState) => {
				return handleAddDistributor(prevState, available, sourceId, distributorId, indexPosition, temp);
			});

			if (type === 'MASSIVE') return;

			setTimeout(async () => {
				try {
					await orderOrAddDistributors({
						id: pharmacyId || '',
						data: {
							...transactionIds,
							distributors: temp.map(({ order = 0, id: distId }) => ({ distributorId: distId, order })),
						},
					});

					onAfterSimpleAdd?.();

					updateLoopingProviderGTM('add_looping_provider', {
						distributorName: currentDistributor?.summarizedCorporateName || '',
						minimumValue: currentDistributor?.minimumValue || 0,
						businessUnitName: available.name,
						index: indexPosition,
					});
				} catch (error) {
					notify.negative({ description: (error as any).data.errorDescription });
					handleRefetchSelectedDistributors?.();
				}
			}, 400);
		},
		[type, sourceId],
	);

	const handleRemove = useCallback(
		(id: string) => {
			onRemove?.(id);

			setSelected?.((prevState) => handleRemoveDistributor(prevState, sourceId, id));

			if (type === 'MASSIVE') return;

			const currentDistributorIndex = selected?.distributors.findIndex((distributor) => distributor.id === id);

			setTimeout(async () => {
				try {
					await deleteDistributors({
						id: pharmacyId || '',
						distributors: {
							...transactionIds,
							distributorId: id,
						},
					});

					onAfterSimpleRemove?.(selected);

					if (typeof currentDistributorIndex === 'number') {
						updateLoopingProviderGTM('remove_looping_provider', {
							distributorName: selected?.distributors[currentDistributorIndex]?.summarizedCorporateName || '',
							minimumValue: selected?.distributors[currentDistributorIndex]?.minimumValue || 0,
							businessUnitName: available.name,
							index: currentDistributorIndex,
						});
					}

					setSelected?.((prevState) => handleRePositionDistributor(prevState, sourceId));
				} catch (error) {
					notify.negative({ description: (error as any).data.errorDescription });
					handleRefetchSelectedDistributors?.();
				}
			}, 400);
		},
		[type, sourceId, selected],
	);

	const handleRedirectToCatalogWithOpenedSideCart = () => {
		const haveToChangePos = posStore.selectedPos.id !== pharmacyId;

		if (haveToChangePos) {
			handleSelectPos(pharmacyId || '', true, true);
			return;
		}

		navigate('/', { state: { openSideBasket: true } });
	};

	const handleOpenRemoveModal = async (distributorId: string) => {
		if (type === 'MASSIVE') {
			handleRemove(distributorId);
			return;
		}

		try {
			await handleGetBasketData({ loading: 'refetch' });

			const hasBasketWithDistributor = basketData?.baskets?.some((basket) => basket.distributorId === distributorId);

			if (!hasBasketWithDistributor) {
				handleRemove(distributorId);
				return;
			}

			await handleOpenDialog({
				heading: {
					title: 'Deseja continuar?',
				},
				content: {
					description:
						'Atenção! Caso tenha algum pedido em seu carrinho do(s) distribuidor(es) alterado(s), o mesmo será excluído. Deseja continuar?',
				},
				footer: {
					primaryButton: {
						label: 'Ver carrinho',
						onClick: handleRedirectToCatalogWithOpenedSideCart,
					},
					secondaryButton: {
						label: 'Confirmar',
						onClick: () => {
							if (distributorId) handleRemove(distributorId);
						},
					},
				},
			});
		} catch {
			console.log('Erro ao abrir modal de remoção');
		}
	};

	const handleReorder = useCallback(
		async (from: number, to: number) => {
			const temp: BusinessUnitResponse['distributors'] = [];

			onReorder?.(from, to);

			setSelected?.((prevState) => handleReorderDistributor(prevState, sourceId, from, to, temp));

			if (type === 'MASSIVE') return;

			setTimeout(async () => {
				try {
					await orderOrAddDistributors({
						id: pharmacyId || '',
						data: {
							...transactionIds,
							distributors: temp.map(({ order = 0, id: distributorId }) => ({ distributorId, order })),
						},
					});
				} catch (error) {
					notify.negative({ description: (error as any).data.errorDescription });
					handleRefetchSelectedDistributors?.();
				}
			}, 400);
		},
		[sourceId, handleRefetchSelectedDistributors, pharmacyId, setSelected, type],
	);

	const handleClickMakeOrder = async () => {
		if (!canBuy)
			return notify.negative({
				title: 'Você possui uma lista de distribuidores vazia.',
				description: 'Por favor selecione ao menos 1 distribuidor para cada indústria em que você possui atendimento.',
			});

		const isValidDistributors = validateDistributors?.();

		if (isValidDistributors) {
			handleSelectPos(pharmacyId || '', true);
			return;
		}

		handleOpenDialog({
			heading: {
				title: 'Você precisa escolher seus distribuidores',
				showCloseButton: true,
			},
			content: {
				description:
					'Para visualizar os produtos na loja e realizar compras, é necessário configurar ao menos um distribuidor para cada indústria em que você possui atendimento.',
			},
			footer: {
				primaryButton: {
					label: 'Salvar e sair da página',
					onClick: () => navigate('/minhas-farmacias'),
				},
			},
		});
	};

	const handleOpenConflicts = () => {
		handleOpenDialog({
			heading: {
				title: 'PDVs onde não será aplicada a configuração dos distribuidores',
				showCloseButton: true,
				typographyProps: {
					variant: 'Paragraph/Semibold',
				},
			},
			content: {
				description: <DistributorsConflicts buName={available.name} conflicts={conflicts} />,
			},
			footer: {
				primaryButton: {
					label: 'Ok, fechar',
				},
			},
		});
	};

	const getBUHelperText = () => {
		if (customDescription) return customDescription;

		if (isPED)
			return 'Agora você pode comprar pelo Parceiro Hypera aproveitando as ofertas do seu distribuidor exclusivo!';

		if (available.name === 'Marcas')
			return (
				<Typography>
					Caso algum produto esteja indisponível no distribuidor selecionado no momento da compra, o{' '}
					<b>enviaremos automaticamente para o distribuidor seguinte</b>, respeitando a ordem de prioridade aqui
					definida.
				</Typography>
			);

		return (
			<Typography>
				Para comprar produtos de {available.name},{' '}
				<b>os distribuidores selecionados servirão apenas de apoio na comparação de preços</b>. O pedido será finalizado
				no distribuidor de sua escolha no momento da compra.
			</Typography>
		);
	};

	const hasSelectedDistributorsChanged =
		JSON.stringify(lastDistributorConfigSaved?.[sourceId] || []) !==
			JSON.stringify(selected?.[BusinessUnitResponseEnum.Distributors]) && !!selected?.distributors;

	const callToActionProps: DistributorsCallToActionProps = {
		type,
		currentBUName: available.name,
		loadingPharmacy: posStore.loading || checkLoading,
		hasSelectedDistributorsChanged,
		handleClickMakeOrder,
		setLastDistributorConfigSaved,
	};

	const filterComponent = !isPED && type === 'SIMPLE' && !hideSearch && (
		<Flex margin={{ medium: '0 0 -8px' }}>
			<Input
				name="search"
				onChange={handleFilter}
				value={filter}
				type="search"
				placeholder="Buscar distribuidor"
				disabled={handleLoading?.().get(['DISTRIBUTORS'])}
			/>
		</Flex>
	);

	const columnsProps: ColumnsProps = {
		isDrawer,
		availableProps: {
			buId: sourceId,
			isPed: isPED,
			filter,
			posList,
			selected,
			isTablet,
			available,
			CustomCTA,
			pharmacyId: pharmacyId || '',
			hideMinValue: type === 'MASSIVE',
			hasPermission,
			filterComponent,
			callToActionProps,
			selectedDistributors: selected,
			handleAdd,
		},
		selectedProps: {
			buId: sourceId,
			isPed: isPED,
			posList,
			isTablet,
			CustomCTA,
			pharmacyId: pharmacyId || '',
			hideMinValue: type === 'MASSIVE',
			hasPermission,
			filterComponent,
			businessUnitName: available.name,
			callToActionProps,
			selectedDistributors: selected,
			onRemove: handleOpenRemoveModal,
			onReorder: handleReorder,
			handleLoading,
		},
		onAdd: handleAdd,
		onReorder: handleReorder,
		onRemove: isDrawer ? handleRemove : handleOpenRemoveModal,
	};

	useEffect(() => {
		if (pharmacyId === posStore.selectedPos.id && selected)
			handleUpdateDistributor({ ...selected, type: sourceType || 'businessUnit' });
	}, [selected]);

	return (
		<Flex direction="column" gap="48px">
			<Flex direction="column" gap="16px">
				<Grid
					gap={isDrawer ? '16px' : { small: '16px', medium: '48px' }}
					columns={type === 'MASSIVE' && !isDrawer ? { small: '1fr', medium: '1fr 1fr' } : '1fr'}
				>
					<Flex direction="column" gap="16px">
						{!customDescription && (
							<Flex alignSelf="start" as={Typography} variant="Headline/H2 Bold" id="buTitle">
								Configuração dos distribuidores de {available.name}
							</Flex>
						)}

						<Typography>{getBUHelperText()}</Typography>
					</Flex>

					{type === 'MASSIVE' && (
						<Flex
							padding="24px"
							borderRadius="8px"
							backgroundColor="--surface-primary"
							height="fit-content"
							gap="16px"
							maxWidth={isDrawer ? undefined : { large: '554px' }}
							margin="0 0 0 auto"
						>
							<Icon name="info" fill color="--text-secondary" size="24px" />

							<Typography>
								<strong>Atenção:</strong> Caso tenha algum pedido em seus carrinhos dos distribuidores alterados, o
								mesmo será excluído.
							</Typography>
						</Flex>
					)}
				</Grid>

				{!!Object.keys(conflicts || {}).length && !!available.distributors.length && (
					<Alert
						type="info"
						title="A configuração dos distribuidores não será aplicada para alguns PDVs da sua seleção."
					>
						<Flex direction={{ small: 'column', medium: 'row' }} alignItems="start" justifyContent="space-between">
							<Typography>Você pode conferir por que isso aconteceu.</Typography>

							<Flex
								margin={{ medium: '-22px 0 0' }}
								as={Button}
								width={{ small: '100%', medium: 'fit-content' }}
								variant="secondary"
								onClick={handleOpenConflicts}
							>
								Visualizar PDVs
							</Flex>
						</Flex>
					</Alert>
				)}

				{hasSelectedDistributorsChanged && type === 'MASSIVE' && !isDrawer && (
					<Alert type="warn">As alterações realizadas na sua lista de distribuidores ainda não foram salvas.</Alert>
				)}

				{alertMessage && <Alert type="warn">{alertMessage}</Alert>}

				{!forceShowColumns && !available.distributors?.length && type === 'MASSIVE' && (
					<Alert
						type="warn"
						title={
							!emptyAvailableDistributorsMessage
								? 'Não foram encontrados distribuidores que atendam ao filtro selecionado.'
								: undefined
						}
					>
						{emptyAvailableDistributorsMessage || 'Por favor revise a seleção de UF e Farmácias.'}
					</Alert>
				)}
			</Flex>
			{(!!available.distributors?.length || type === 'SIMPLE' || forceShowColumns) && (
				<DistributorsDragAndDrop {...columnsProps} />
			)}
		</Flex>
	);
}
