import { ApmBase, apm } from '@elastic/apm-rum';
import { createContext, useContext, useEffect } from 'react';
import { getUserFromLocalStorage } from 'shared/user';
import version from '../../version';
import { generateAssetsHash } from 'shared/hashes';

const ElasticApmContext = createContext(
	{} as { apm: ApmBase; setAPMUser: ({ id, email, name }: Record<'id' | 'email' | 'name', string>) => void },
);

export default function useElasticApm() {
	return useContext(ElasticApmContext);
}

export function ElasticAPMProvider({ children }: { children: React.ReactNode }) {
	const setAPMUser = ({ id, email, name }: Record<'id' | 'email' | 'name', string>) => {
		apm.setUserContext({ id, email, username: name });
	};

	useEffect(() => {
		if (import.meta.env.VITE_APM_SERVER_URL) {
			if (!window.sessionStorage.getItem('@PHC:userTabSession')) {
				window.sessionStorage.setItem('@PHC:userTabSession', generateAssetsHash());
			}

			const { id, name, email } = getUserFromLocalStorage()?.data || {};

			apm.config({
				serviceName: import.meta.env.VITE_APM_SERVICE_NAME || 'hypera-ph-commerce',
				serverUrl: import.meta.env.VITE_APM_SERVER_URL || 'http://localhost:8200',
				serviceVersion: 'hypera-ph-commerce-version',
				environment: import.meta.env.VITE_APM_ENV || 'local',
				pageLoadTransactionName: `${id}/app-load`,
			});

			setAPMUser({ id, email, name });

			apm.setCustomContext({
				frontVersion: version.frontVersion || 'not-set',
				userTabSession: window.sessionStorage.getItem('@PHC:userTabSession') || 'not-set',
			});

			apm.init();
		}
	}, [apm]);

	return <ElasticApmContext.Provider value={{ apm, setAPMUser }}>{children}</ElasticApmContext.Provider>;
}
